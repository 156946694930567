import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';

export const IsMobile = () => {
	return !useMediaQuery('(min-width:900px)');
};

export const sleep = (time) => {
	return new Promise((resolve) => {
		setTimeout(() => {
			return resolve();
		}, time);
	});
};

export const getTodayDayDelta = (date) => {
	const today = new Date();
	const diffTime = date - today;
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export function objectEmpty(obj) {
	return Object.keys(obj).length === 0;
}

export const groupBy = (arr, key) => {
	return arr.reduce((rv, x) => {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};
export const groupByMonth = (arr, key) => {
	return arr.reduce((rv, x) => {
		(rv[`${new Date(x[key]).getMonth()}/${new Date(x[key]).getFullYear()}`] =
			rv[`${new Date(x[key]).getMonth()}/${new Date(x[key]).getFullYear()}`] || []).push(x);
		return rv;
	}, {});
};

export const capitalizeFirstLetter = (string, locale = navigator.language) => {
	if (string?.length > 2) {
		if (string.split(' ').length > 1) {
			return string
				.split(' ')
				.map((word) => word.charAt(0).toLocaleUpperCase(locale) + word.slice(1).toLocaleLowerCase(locale))
				.join(' ');
		}
		return string.charAt(0).toLocaleUpperCase(locale) + string.slice(1).toLocaleLowerCase(locale);
	} else {
		return string;
	}
};

export const renderDate = (takeFrom, t) => {
	const postDate = DateTime.fromISO(takeFrom.created_at).toFormat('MMMM dd, yyyy');
	const nowDate = DateTime.now().toFormat('MMMM dd, yyyy');

	if (postDate === nowDate) {
		return t('Today');
	} else {
		return postDate;
	}
};

/** Funcion para obtener color complmenetario a partir del color primario principal tomada de internet */
export const getComplementaryColor = (hex) => {
	let r = hex.length === 4 ? parseInt(hex[1] + hex[1], 16) : parseInt(hex.slice(1, 3), 16);
	let g = hex.length === 4 ? parseInt(hex[2] + hex[2], 16) : parseInt(hex.slice(3, 5), 16);
	let b = hex.length === 4 ? parseInt(hex[3] + hex[3], 16) : parseInt(hex.slice(5), 16);

	[r, g, b] = complementaryRGBColor(r, g, b);
	return (
		'#' +
		(r < 16 ? '0' + r.toString(16) : r.toString(16)) +
		(g < 16 ? '0' + g.toString(16) : g.toString(16)) +
		(b < 16 ? '0' + b.toString(16) : b.toString(16))
	);
};

function complementaryRGBColor(r, g, b) {
	if (Math.max(r, g, b) === Math.min(r, g, b)) {
		return [255 - r, 255 - g, 255 - b];
	} else {
		r /= 255;
		g /= 255;
		b /= 255;
		let max = Math.max(r, g, b),
			min = Math.min(r, g, b);
		let h,
			s,
			l = (max + min) / 2;
		let d = max - min;
		s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

		switch (max) {
			case r:
				h = (g - b) / d + (g < b ? 6 : 0);
				break;
			case g:
				h = (b - r) / d + 2;
				break;
			default:
				h = (r - g) / d + 4;
				break;
		}

		h = Math.round(h * 60 + 180) % 360;
		h /= 360;

		function hue2rgb(p, q, t) {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
			return p;
		}

		let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
		let p = 2 * l - q;

		r = hue2rgb(p, q, h + 1 / 3);
		g = hue2rgb(p, q, h);
		b = hue2rgb(p, q, h - 1 / 3);

		return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
	}
}
