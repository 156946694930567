import { CSSProperties } from 'react';
import { PaletteOptions, SimplePaletteColorOptions, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { getComplementaryColor } from './utils';

// TODO: create MUI theme https://mui.com/customization/theming/

const MOTIVY_DEFAULT_MAIN_COLOR = '#3a71b5';

/**
 *
 * @param mainColor Main color to be used according to the organization needs
 * @param secondaryColor Secondary color to be used according to the organization needs
 * @returns A complete, ready-to-use theme object.
 */
export function getTheme(mainColor = MOTIVY_DEFAULT_MAIN_COLOR, secondaryColor = getComplementaryColor(mainColor)) {
	const CURRENT_PALETTE: PaletteOptions = {
		primary: {
			main: mainColor,
		},
		secondary: {
			main: secondaryColor,
		},
		commentBackground: {
			main: "#D8E9FD"
		},
		error: {
			main: '#f34949',
			light: '#F1756A',
			dark: '#de3b3b',
			lighter: '#F89A87',
		},
		warning: {
			main: '#f0c30f',
			dark: '#E57700',
			light: '#FDB749',
			lighter: '#FDCC72',
		},
		info: {
			main: '#0081fa',
			dark: '#005EC4',
			light: '#5DB3EF',
			lighter: '#9FDDF9',
		},
		success: {
			main: '#15B750',
			dark: '#0DA160',
			light: '#2DE671',
			lighter: '#57EA70',
		},
		dark: {
			main: '#666D8E',
			dark: '#36373F',
			light: '#8F93AA',
			lighter: '#C6CADC',
		},
		light: {
			main: '#EBEDF0',
			dark: '#DDE2E9',
			light: '#F1F2F4',
			lighter: '#F9FAFB',
		},
		chip: {
			main: '#338EFF',
			dark: '#0057C2',
			light: '#66ABFF',
			lighter: '#99C7FF',
		},
		ratingBlue: {
			main: '#4994F3',
			dark: '#2369D1',
			light: '#95C4F8',
			lighter: '#C9E3FB',
		},
		ratingViolet: {
			main: '#7649F3',
			dark: '#5623D1',
			light: '#B795F8',
			lighter: '#DCC9FB',
		},
		ratingGreen: {
			main: '#0CC075',
			dark: '#1CA65F',
			light: '#96F8B1',
			lighter: '#CCFBD5',
		},
		ratingFucsia: {
			main: '#B50CC0',
			dark: '#9F09B9',
			light: '#F896E6',
			lighter: '#FBCCEF',
		},
	};

	const MAIN_COLOR = CURRENT_PALETTE?.primary as SimplePaletteColorOptions;
	const LIGHT_COLOR = CURRENT_PALETTE?.light as SimplePaletteColorOptions;
	const CHIP_COLOR = CURRENT_PALETTE?.chip as SimplePaletteColorOptions;
	const DARK_COLOR = CURRENT_PALETTE?.dark as SimplePaletteColorOptions;
	const LABEL_COLOR = '#3377FF';

	return responsiveFontSizes(
		createTheme({
			// * https://mui.com/material-ui/customization/palette/
			palette: CURRENT_PALETTE,
			// * https://mui.com/material-ui/customization/spacing/
			spacing: 4,
			// * https://mui.com/material-ui/customization/typography/
			typography: {
				fontFamily: 'Lato',
				h1: {
					fontSize: '44px',
					fontWeight: '800',
					color: MAIN_COLOR.main,
				},
				h2: {
					fontSize: '36px',
					fontWeight: '700',
					color: MAIN_COLOR.main,
				},
				h3: {
					fontSize: '28px',
					fontWeight: '700',
					color: MAIN_COLOR.main,
				},
				h4: {
					fontSize: '24px',
					fontWeight: '700',
					color: MAIN_COLOR.main,
				},
				h5: {
					fontSize: '20px',
					fontWeight: '700',
					color: MAIN_COLOR.main,
				},
				h6: {
					fontSize: '16px',
					fontWeight: '700',
					color: MAIN_COLOR.main,
				},
				leadParagraph: {
					fontSize: '22px',
					fontWeight: '400',
				},
				bodyLarge: {
					fontSize: '20px',
					fontWeight: '400',
				},
				bodyMedium: {
					fontSize: '18px',
					fontWeight: '400',
				},
				bodyNormal: {
					fontSize: '16px',
					fontWeight: '400',
				},
				bodySmall: {
					fontSize: '14px',
					fontWeight: '400',
				},
				labelTextXS: {
					fontSize: '10px',
					fontWeight: '400',
					color: LABEL_COLOR,
				},
				labelTextSM: {
					fontSize: '12px',
					fontWeight: '400',
					color: LABEL_COLOR,
				},
				labelText: {
					fontSize: '14px',
					fontWeight: '600',
					color: LABEL_COLOR,
				},
				labelTextLG: {
					fontSize: '16px',
					fontWeight: '600',
					color: LABEL_COLOR,
				},
				labelTextXL: {
					fontSize: '18px',
					fontWeight: '600',
					color: LABEL_COLOR,
				},
			},
			// * https://mui.com/material-ui/customization/theme-components/
			components: {
				MuiTooltip: {
					styleOverrides: {
						tooltip: {
							color: LIGHT_COLOR.main,
							backgroundColor: MAIN_COLOR.main
						},
						arrow: {
							color: MAIN_COLOR.main
						}
					}
				},
				MuiTab: {
					styleOverrides: {
						root: {
							'&:hover': { backgroundColor: LIGHT_COLOR.dark },
							borderBottom: `4px solid ${LIGHT_COLOR.main}`,
						},
					},
				},
				MuiTabs: {
					styleOverrides: {
						indicator: {
							height: 4,
						},
					},
				},
				MuiAvatar: {
					variants: [
						{
							props: { variant: 'clickable' },
							style: {
								border: '2px solid #FFFFFF',
								'&:hover': {
									outline: `3px solid ${MAIN_COLOR.main}`,
								},
							},
						},
						{
							props: { variant: 'userImage' },
							style: {
								border: '2px solid #FFFFFF',
								backgroundColor: '#FFFFFF',
								color: MAIN_COLOR.main,
								boxShadow: `inset 0 0 0 1000px ${MAIN_COLOR.main + '40'}`,
								svg: {
									fill: 'none',
									stroke: MAIN_COLOR.main,
									strokeWidth: '1.5',
								},
							},
						},
					],
				},
				MuiAccordion: {
					styleOverrides: {
						root: {
							":before": {
								backgroundColor: "transparent"
							},
						}
					}
				},
				MuiAccordionSummary: {
					styleOverrides: {
						root: {
							paddingLeft: "24px"
						},
					},
				},
				MuiAccordionDetails: {
					styleOverrides: {
						root: {
							padding: "0 24px 24px"
						}
					}
				},
				MuiButton: {
					styleOverrides: {
						sizeSmall: {
							padding: '12px 24px',
							fontSize: '14px',
						},
						sizeMedium: {
							padding: '14px 32px',
							fontSize: '16px',
						},
						sizeLarge: {
							padding: '16px 48px',
							fontSize: '18px',
						},
						root: {
							textTransform: 'none',
							lineHeight: '1',
							borderRadius: 8,
						},
					},
				},
				MuiIcon: {
					styleOverrides: {
						root: {
							fontSize: '24px',
						},
						fontSizeSmall: {
							fontSize: '16px',
						},
						fontSizeLarge: {
							fontSize: '48px',
						},
					},
				},
				MuiBadge: {
					styleOverrides: {
						badge: {
							fontWeight: "bold",
						}
					}
				},
				MuiSvgIcon: {
					styleOverrides: {
						root: {
							fontSize: '24px',
						},
						fontSizeSmall: {
							fontSize: '16px',
						},
						fontSizeLarge: {
							fontSize: '48px',
						},
					},
				},
				MuiIconButton: {
					styleOverrides: {
						sizeSmall: {
							padding: '12px',
							fontSize: '14px',
						},
						sizeMedium: {
							padding: '14px',
							fontSize: '16px',
						},
						sizeLarge: {
							padding: '16px',
							fontSize: '18px',
						},
					},
				},
				MuiChip: {
					styleOverrides: {
						root: {
							fontWeight: 'bold',
							width: 'fit-content',
							height: '40px',
							borderRadius: '12px',
							paddingLeft: "12px",
							paddingRight: "12px",
						},
						filled: {
							backgroundColor: CHIP_COLOR.lighter,
							color: DARK_COLOR.dark,
						},
						outlined: {
							border: `1px solid ${CHIP_COLOR.light}`,
							color: CHIP_COLOR.light
						},
						labelSmall: {
							marginTop: '0 !important',
						}
					},
				},
				MuiSelect: {
					styleOverrides: {
						outlined: {
							backgroundColor: 'white',
							padding: "16px 20px",
							maxHeight: "20px"
						},
					},
				},
				MuiTextField: {
					styleOverrides: {
						root: {
							'&.disabled': {
								'& fieldset': {
									border: 'none',
								},
								'& input': {
									cursor: 'default',
								},
							},
						},
					},
				},
				MuiModal: {
					styleOverrides: {
						root: {
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						},
					},
				},
				MuiDialog: {
					styleOverrides: {
						container: {
							height: 'inherit',
							padding: 8,
						},
						paperFullScreen: {
							borderRadius: 8,
						},
					},
				},
			},
		}),
	);
}

// * Add new properties
declare module '@mui/material/styles' {
	// * Add new typography variants
	interface TypographyVariantsOptions {
		leadParagraph: CSSProperties;
		bodyLarge: CSSProperties;
		bodyMedium: CSSProperties;
		bodyNormal: CSSProperties;
		bodySmall: CSSProperties;
		labelTextXS: CSSProperties;
		labelTextSM: CSSProperties;
		labelText: CSSProperties;
		labelTextLG: CSSProperties;
		labelTextXL: CSSProperties;
	}

	// * Add new color
	interface Palette {
		dark: Palette['primary'];
		light: Palette['primary'];
		chip: Palette['primary'];
		ratingBlue: PaletteOptions['primary'];
		ratingViolet: PaletteOptions['primary'];
		ratingGreen: PaletteOptions['primary'];
		ratingFucsia: PaletteOptions['primary'];
		commentBackground: PaletteOptions['primary'];
	}

	interface PaletteOptions {
		dark: PaletteOptions['primary'];
		light: PaletteOptions['primary'];
		chip: PaletteOptions['primary'];
		ratingBlue: PaletteOptions['primary'];
		ratingViolet: PaletteOptions['primary'];
		ratingGreen: PaletteOptions['primary'];
		ratingFucsia: PaletteOptions['primary'];
		commentBackground: PaletteOptions['primary'];
	}

	// * Add new color contrast
	interface PaletteColor {
		lighter?: string;
		dark: string;
	}

	interface PaletteColorOptions {
		dark?: string;
		main: string;
		light?: string;
		lighter?: string;
	}

	interface SimplePaletteColorOptions {
		lighter?: string;
	}
}

// * Add new Avatar variants
declare module '@mui/material/Avatar' {
	interface AvatarPropsVariantOverrides {
		userImage: true;
		clickable: true;
	}
}

// * Toggle typography activation
declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		leadParagraph: true;
		bodyLarge: true;
		bodyMedium: true;
		bodyNormal: true;
		bodySmall: true;
		labelTextXS: true;
		labelTextSM: true;
		labelText: true;
		labelTextLG: true;
		labelTextXL: true;
		body1: false;
		body2: false;
		button: false;
		subtitle1: false;
		subtitle2: false;
		caption: false;
		overline: false;
	}
}

// Update the AppBar's color prop options
declare module '@mui/material/AppBar' {
	interface AppBarPropsColorOverrides {
		primaryDark: true;
	}
}

// Update the AppBar's color prop options
declare module '@mui/material/Badge' {
	interface BadgePropsColorOverrides {
		primaryInvert: true;
	}
}

export type themeProps = {
	banner: {
		mainColor: string;
		text: string;
	};
};
