import {createContext} from "react";
import {ApiContextValue} from "@/types";

export const ApiContext = createContext<ApiContextValue>({
    // Responses
    apiResponses: {
      token: null,
      createdCommunicationGroup: null
    },
    // GET
    // POST
    post: {
        setTeamsSession: () => Promise.resolve(null),
    },
    // PATCH
    // DELETE
    // PUT
})