import { SET_CATALOGS } from "./actions";

const INITIAL_STATE = {
    catalogs: [],
};

export default function catalogsReducer(state = INITIAL_STATE, action) {
    if ( action.type === SET_CATALOGS ) return { ...state, catalogs: action.catalogs }
    return state
}
