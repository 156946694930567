import { SET_GOALS } from "./actions";
import { UPDATE_GOAL } from "./actions";

const INITIAL_STATE = {
  goals: [],
};

export default function goalsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_GOALS:
      return { ...state, goals: action.goals };
    case UPDATE_GOAL:
      return {
        ...state,
        goals: [
          ...state.goals.filter((g) => g.id !== action.goal.id),
          action.goal,
        ],
      };
    default:
      return state;
  }
}
