import {useSelector} from "react-redux";
import store, {RootState} from "@/redux/store";
import {Stack} from "@mui/material";
import MotivyAlert from "@/ui2.0/Alerts/MotivyAlert";
import {deleteStreamedMessage} from "@/redux/actions";
import {FC, ReactElement} from "react";

const StreamedMessages: FC = ():ReactElement => {
    const streamedMessages = useSelector((state: RootState) => state.streamedMessages)

    return <Stack
        direction={'column-reverse'}
        position={'absolute'}
        bottom={'2rem'}
        right={'2rem'}
        spacing={2}
        zIndex={3000}
    >
        {streamedMessages.map((response: any) => {
            // alert(response.message)
            return <MotivyAlert
                key={response.id}
                type={response.severity}
                message={response.message}
                onClose={() => store.dispatch(deleteStreamedMessage(response.id))}
            />
        })}
    </Stack>
}

export default StreamedMessages