import { SET_CHARTS } from "./actions";

const INITIAL_STATE = {
  charts: [],
};

export default function chartsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CHARTS:
      return { ...state, charts: action.charts };
    default:
      return state;
  }
}
