import { SET_DEPARTMENTS } from "./actions";
import { UPDATE_DEPARTMENT } from "./actions";

const INITIAL_STATE = {
  departments: [],
};

export default function departmentsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DEPARTMENTS:
      return { ...state, departments: action.departments };
    case UPDATE_DEPARTMENT:
      return {
        ...state,
        departments: [
          ...state.departments.filter((d) => d.id !== action.department.id),
          action.department,
        ],
      };
    default:
      return state;
  }
}
