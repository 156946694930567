import { TRIGGER_WHATSAPP} from "@/redux/actions";

const INITIAL_STATE = {
    show: false,
};

export default function whatsappTriggerReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TRIGGER_WHATSAPP:
            return { ...state, show: action.show };
        default:
            return state;
    }
}