import { SET_NOTIFICATIONS_COUNT } from "./actions";

const INITIAL_STATE = {
  count: 0,
};

export default function notificationsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_NOTIFICATIONS_COUNT:
      return { ...state, count: action.count };
    // We can do case for setting actual notifications objects
    default:
      return state;
  }
}
