import React, {Suspense} from "react";
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux";
import App from "@/App";
import "./index.scss";
import "./index.css";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import "./i18n";

import "./components/styles/base.scss";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import {
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router-dom";


if ( !/.localhost.*/.test(window.origin)){
    Sentry.init({
        // dsn: "https://f6af6079efda4036a23ce492ec9ef101@o1186451.ingest.sentry.io/4504101213962240",
        integrations: [new BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                React.useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            ),
        })],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}



const root = createRoot(document.getElementById("root"))

root.render(
    <Provider store={store}>
        <Suspense fallback={null}>
            <App />
        </Suspense>
    </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (/.*pdb\.motivy.*/.test(window.origin)) {
    serviceWorker.register();
} else {
    serviceWorker.unregister();
}
