import { SET_PERIODS } from "./actions";

const INITIAL_STATE = {
    periods: [],
};

export default function periodsReducer(state = INITIAL_STATE, action) {
    if ( action.type === SET_PERIODS ) return { ...state, periods: action.periods }
    return state
}
