import {FC, ReactElement, useEffect, useState} from "react";
import {Alert, AlertTitle, Fade} from "@mui/material";
import {SeverityTypes} from "@/types";

interface MotivyAlertProps {
    message: string
    type: SeverityTypes
    onClose: () => void
}

const MotivyAlert: FC<MotivyAlertProps> = (props): ReactElement => {

    const [open, setOpen] = useState<boolean>(true)
    const {message, type, onClose} = props

    useEffect(() => {
        setTimeout(() => {
            setOpen(false)
        }, 4500)
        return () => {
            onClose()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Fade in={open} unmountOnExit>
        <Alert severity={type} variant={'outlined'}>
            <AlertTitle>Motivy</AlertTitle>
            {message}
        </Alert>
    </Fade>
    // </Snackbar>
}

export default MotivyAlert