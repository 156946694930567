import React, {FC, ReactElement} from "react";
import MotivyLogo from "@/assets/imagotipo-white.svg";

import "./LoaderPage.css";

const LoaderPage: FC = ():ReactElement => {
    return (
        <div className="loader-container">
            <div className={"loader"}>
                <img src={MotivyLogo} alt="Motivy Logo" />
                <div className="text-animation-container" >
                    <div className="text-container">
                        <div>El poder del reconocimiento</div>
                        <div>the power of recognition</div>
                        <div>le pouvoir de la reconnaissance</div>
                        <div>o poder do reconhecimento</div>
                        <div>сила признания</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderPage;