import { SET_TASKS } from "./actions";
import { UPDATE_TASKS } from "./actions";

const INITIAL_STATE = {
  tasks: [],
};

export default function tasksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_TASKS:
      return { ...state, tasks: action.tasks };
    case UPDATE_TASKS:
      const { tasks } = state;
      const _tasks = tasks.slice(0);
      _tasks.push(action.task);
      return { ...state, tasks: _tasks };
    default:
      return state;
  }
}
