import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk"
import meReducer from "./meReducer";
import accountReducer from "./accountReducer";
import cartReducer from "./cartReducer";
import chartsReducer from "./chartsReducer";
import postsReducer from "./postsReducer";
import notificationsReducer from "./notificationsReducer";
import tasksReducer from "./tasksReducer";
import programsReducer from "./programsReducer";
import orgReducer from "./orgReducer";
import featuredItemsReducer from "./featuredItemsReducer";
import settingsTriggerReducer from "./settingsTriggerReducer";
import bannerReducer from "./bannerReducer";
import departmentsReducer from "./departmentReducer";
import postTypesReducer from "./postTypesReducer";
import goalsReducer from "./goalsReducer";
import deviceReducer from "./deviceReducer";
import surveyCountReducer from "./surveyCountReducer";
import goalCategoriesReducer from "./goalCategoryReducer"
import servicesReducer from "@/redux/servicesReducer";
import languagesReducer from "@/redux/languagesReducer";
import periodsReducer from "@/redux/periodsReducer";
import countriesReducer from "@/redux/countriesReducer";
import catalogsReducer from "@/redux/catalogsReducer"
import commonsReducer from "@/redux/commonsReducer";
import streamedMessagesReducer from "@/redux/streamedMessagesReducer";
import whatsappTriggerReducer from "@/redux/whatsappTriggerReducer";
import eventReducer from "@/redux/eventsReducer"

const rootReducer = combineReducers({
  cart: cartReducer,
  me: meReducer,
  account: accountReducer,
  posts: postsReducer,
  charts: chartsReducer,
  notifications: notificationsReducer,
  tasks: tasksReducer,
  programs: programsReducer,
  org: orgReducer,
  featuredItems: featuredItemsReducer,
  showSettings: settingsTriggerReducer,
  showWhatsapp: whatsappTriggerReducer,
  banner: bannerReducer,
  departments: departmentsReducer,
  post_types: postTypesReducer,
  goals: goalsReducer,
  device: deviceReducer,
  pendingSurveys: surveyCountReducer,
  categories: goalCategoriesReducer,
  services: servicesReducer,
  languages: languagesReducer,
  periods: periodsReducer,
  countries: countriesReducer,
  catalogs: catalogsReducer,
  commons: commonsReducer,
  streamedMessages: streamedMessagesReducer,
  event: eventReducer
});
// createStore deprecated?
// devtoolsEnhancer middleware goes here (see redux-devtools-extension)
// const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(thunkMiddleware)));
const store = createStore(rootReducer, applyMiddleware(thunkMiddleware))
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
