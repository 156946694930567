import {SET_SERVICES} from "@/redux/actions";
import {Services} from "@/types";

const INITIAL_STATE = {
    type: SET_SERVICES,
    analytics: { active: false },
    benefits: { active: false },
    catalog: { active: false },
    communications: { active: false },
    complaints: { active: false },
    evaluations: { active: false },
    events: { active: false },
    fullstory: { active: false },
    goals: { active: false },
    notifications: { active: false },
    profile: { active: false },
    programs: { active: false },
    recognitions: { active: false },
    surveys: { active: false },
    tasks: { active: false },
    auth: { active: true },
}

interface ServicesReducer extends Services {
    type: string
}

export default function servicesReducer(state: ServicesReducer = INITIAL_STATE, action: ServicesReducer ) {
    switch ( action.type ) {
        case SET_SERVICES:
            return { ...action };
        default:
            return state;
    }
}