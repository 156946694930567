import { SET_GOAL_CATEGORIES } from "./actions";
import { UPDATE_GOAL_CATEGORY } from "./actions";

const INITIAL_STATE = {
    categories: [],
};

export default function goalCategoriesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_GOAL_CATEGORIES:
            return { ...state, categories: action.categories };
        case UPDATE_GOAL_CATEGORY:
            return {
                ...state,
                categories: [
                    ...state.categories.filter((c) => c.id !== action.category.id),
                    action.category,
                ],
            };
        default:
            return state;
    }
}
