import { SET_LANGUAGES } from "./actions";

const INITIAL_STATE = {
    languages: {},
};

export default function languagesReducer(state = INITIAL_STATE, action) {
    if ( action.type === SET_LANGUAGES ) return { ...state, languages: action.languages }
    return state
}
