import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import "./styles/Navigation.sass";
import { connect } from "react-redux";
import { compose } from "redux";
import { withTranslation } from "react-i18next";


export const history = createBrowserHistory({ forceRefresh: true });

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  render() {
    let { isDesktop } = this.props;

    // TODO: change this validation of types
    return (
      <Router history={history}>
        {this.props.pendingSurveys > 0 && isDesktop && (
          <>
            <span
              onClick={() => history.push("/notifications/surveys")}
              className="secondaryDarktButton pointer"
              style={{
                padding: 10,
                paddingRight: 5,
                position: "fixed",
                top: "200px",
                right: "0px",
                backgroundColor: "blue",
                writingMode: "vertical-rl",
                color: "white",
                fontSize: 16,
                fontWeight: 700,
              }}
            >
              {"Encuestas Pendientes"}
            </span>
          </>
        )}
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    me: state.me.me,
    pendingSurveys: state.pendingSurveys.pendingSurveys,
  };
};

export default compose(
  withTranslation("glossary"),
  connect(mapStateToProps)
)(Navigation);
