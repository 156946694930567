import { SET_PROGRAMS } from "./actions";
import { UPDATE_PROGRAMS } from "./actions";

const INITIAL_STATE = {
  programs: [],
};

export default function programsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_PROGRAMS:
      return { ...state, programs: action.programs };
    case UPDATE_PROGRAMS:
      const { programs } = state;
      const _programs = programs.slice(0);
      return { ...state, programs: [action.program].concat(_programs) };
    default:
      return state;
  }
}
