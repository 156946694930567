import {ApiService} from "./networkManager.js";
import store from "../redux/store";
import {clearOrgUsersAction} from "@/redux/actions";

////////////////////////////
////   Modules status   ////
////////////////////////////

export const getAvailableModules = async () => {
	const path = "/org/services";
	// TODO: Check to use redux
	const response = await ApiService.get(path);
	return response["data"];
};

////////////////////////////
//// Account Management ////
////////////////////////////

export const activateAccount = async (data) => {
	const path = "/user/activate";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const login = async (email, password) => {
	const path = "/login";
	const data = { email, password };
	// we got to store cookie.. should I return or store form here?
	return ApiService.post(path, data);
};

export const passwordlessOTPLogin = async (email) => {
	const path = "/otp/login";
	const data = { email };
	return ApiService.post(path, data);
};

export const passwordlessOTPVerifyCode = async (email, code, session) => {
	const path = "/otp/verify_code";
	return ApiService.post(path, { email, code, session });
};

export const getOrgSurveys = async () => {
	const path = "/org/surveys";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getOrgSurveysTypePrograms = async () => {
	const path = "/org/surveys/type_programs";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getOrgAuthFlow = async () => {
	const path = "/org/auth_flow";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addSurvey = async (data) => {
	const path = "/org/survey";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const setOrderStatus = async (data, id) => {
	const path = "/order/status/" + id;
	const res = await ApiService.patch(path, data);
	return res["success"];
};

export const activateWhatsapp = async (phone) => {
		const path = "/user/whatsapp/activate";
		return ApiService.post(path, { phone });
};

export const deactivateWhatsapp = async () => {
	const path = "/user/whatsapp/deactivate";
	return ApiService.post(path);
};

export const changeUserLanguage = async (language) => {
	const res = await ApiService.patch("/user/language", { language });
	return res["success"];
};

export const changeLanguage = async (language) => {
	const path = "/org/language";
	const res = await ApiService.post(path, { language });
	return res["success"];
};

export const getLanguage = async () => {
	const path = "/org/language";
	const res = await ApiService.get(path);
	return res["data"] || "spanish";
};

export const changePassword = async (data) => {
	const path = "/change_password";
	return ApiService.post(path, data);
};

export const updateAccount = async (data) => {
	const path = "/user/account/update";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const updateProfileImage = async (data) => {
	const path = "/user/profile_image";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const updateBackgroundImage = async (data) => {
	const path = "/user/profile_background";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const resetPassword = async (token, data) => {
	const path = `/reset_password/${token}`;
	return ApiService.post(path, data);
};

export const forgotPassoword = async (email) => {
	const path = "/get_reset_email/" + email;
	const res = await ApiService.get(path);
	return res["success"];
};

export const getOrgGoals = async (department, user, start_date, end_date) => {
	const path = "/org/goal";
	const res = await ApiService.get(path, null, {
		department,
		user,
		start_date,
		end_date,
	});
	return res["data"];
};

export const getGoals = async () => {
	const path = "/goal";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getGoalChecklist = async (goalId) => {
	const path = `/goal/${goalId}/checklist`;
	const res = await ApiService.get(path);
	return res["data"];
};

export const getGoalAvailableWeight = async (ownerId) => {
	const path = "/goals/available_weight";
	const res = await ApiService.post(path, { owner_id: ownerId });
	return res["data"];
};

export const reorderGoals = async (id, data) => {
	const path = `/goal/${id}/checklist/reorder`;
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const deleteGoal = async (id) => {
	const path = `/goal/${id}/`;
	const res = await ApiService.delete(path);
	return res["success"];
};

export const requestProfileExport = async () => {
	const path = "/profile/download";
	const res = await ApiService.post(path);
	return res["success"];
};

export const addGoalCheckin = async (data) => {
	const path = "/goal/checkin";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const deleteGoalCheckin = async (id) => {
	const path = `/goal/checkin/${id}/`;
	const res = await ApiService.delete(path);
	return res["success"];
};

export const addOrgGoalCheckin = async (data) => {
	const path = "/org/goal/checkin";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const getGoalPeriods = async () => {
	const path = "/goal_period";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getGoalCategories = async () => {
	const path = "/goal_category";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addPersonalGoal = async (data) => {
	const path = "/goal";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const editPersonalGoal = async (id, data) => {
	const path = `/goal/${id}/`;
	const res = await ApiService.patch(path, data);
	return res["success"];
};

export const editCorporateGoal = async (id, data) => {
	const path = `/org/goal/${id}/`;
	const res = await ApiService.patch(path, data);
	return res["success"];
};

export const addOrgGoal = async (data) => {
	const path = "/org/goal";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const addGoalComment = async (data) => {
	const path = "/goal/comment";
	return await ApiService.post(path, data);
};

////////////////
//// Upload ////
////////////////

const uploadTypes = [
	"comm-attachment",
	"user-profile-picture",
	"task-attachment",
	"program-attachment",
	"suggestion-attachment",
	"complaint-attachment",
	"goalcheckin-attachment",
];
export const getUploadPresign = async (type, data) => {
	if (!uploadTypes.includes(type)) {
		throw new Error(`Invalid file type ${type}`);
	}
	const path = "/upload_presign/" + type;
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const getReadPresign = async (type, data) => {
	const path = "/read_presign/" + type;
	const res = await ApiService.post(path, data);
	return res["data"];
};

////////////////////
//// SearchUser ////
////////////////////
export const searchUser = async (keywords, search_by_email=false) => {
	const path = "/user/search";
	const res = await ApiService.post(path, { keywords, search_by_email });
	return res["data"];
};

export const searchUserTask = async (id, keywords) => {
	const path = `/task/${id}/user/search`;
	const res = await ApiService.post(path, { keywords });
	return res["data"];
};

//////////////////////
//// Landing Page ////
//////////////////////

export const getPosts = async (page) => {
	const path = "/post";
	try {
		const res = await ApiService.get(path, page);
		return { data: res["data"], next: res["next"] };
	} catch (_e) {
		return { data: null, next: false };
	}
};

export const chartSummary = async (department, category) => {
	const path = "/chart/summary";
	const res = await ApiService.get(path, null, { department, category });
	return res["data"];
};

export const chartSummaryV2 = async (department, category, valueFrom, valueTo, sendUsers, country, type) => {
	const path = "/chart/summary/v3";
	const res = await ApiService.get(path, null, { valueFrom, valueTo, department, category, sendUsers, country, type});
	return {data: res["data"]};
};
/**
 * @param {*} receiverId
 * @param {number} amount
 * @param {string} post_type
 * @param {string} msg
 * @param {*} giphy_id
 * @param {*} post_subtype
 */
export const sendFeedback = async (
	receiverId,
	amount,
	post_type,
	msg,
	giphy_id = null,
	post_subtype = null,
) => {
	const path = "/send_feedback";
	const data = {
		receiverIds: receiverId,
		amount,
		post_type,
		post_subtype,
		msg,
		giphy_id,
	};
	return ApiService.post(path, data);
};

export const getPostTypes = async () => {
	const path = "/recognition_type";
	try {
		const res = await ApiService.get(path);
		return res["data"];
	} catch (_e) {
		return null;
	}
};

export const getAllPostTypes = async () => {
	const path = "/recognition_type/all";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addNewPostType = async (data) => {
	const path = "/recognition_type";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const togglePostTypeStatus = async (data) => {
	const path = "/recognition_type/activate";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const featuredProducts = async () => {
	const path = "/product/featured";
	const res = await ApiService.get(path);
	return res["data"];
};

/////////////////////////////////
//// Points Request/Transfer ////
/////////////////////////////////

export const requestPoints = async (data) => {
	const path = "/point_request";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const getPointRequests = async (pending) => {
	const path = "/point_request";
	const res = await ApiService.get(path, null, { pending });
	return res["data"];
};

export const transferPoints = async (data) => {
	const path = "/transfer_points";
	return ApiService.post(path, data);
};

//////////////////////
//// Catalog Page ////
//////////////////////

export const getAllProducts = async (category, justCorporative = false) => {
	let path = "/product";
	path += category !== -1 ? `/category/${category}?just_corporative=${justCorporative}` : "";
	const res = await ApiService.get(path);
	// TODO: Should I return or store?
	return { data: res["data"], next: res["next"] };
};

export const getAllProductsCat = async (page, category) => {
	let path = "/product";
	path += category !== -1 ? `/category/${category}` : "";
	const res = await ApiService.get(path, page);
	// TODO: Should I return or store?
	return { data: res["data"], next: res["next"] };
};

///////////////
//// Tasks ////
///////////////

export const getTasks = async (page, department) => {
	const path = "/task";
	const res = await ApiService.get(path, page, { department });
	return { data: res["data"], next: res["next"] };
};

export const submitTasks = async (data) => {
	const path = "/task/submit";
	return ApiService.post(path, data);
};

export const editTask = async (data) => {
	const path = "/task/edit";
	return ApiService.post(path, data);
};

export const deleteTask = async (taskId) => {
	const path = `/task/${taskId}/`;
	return ApiService.delete(path);
};

export const getTaskSubmissions = async (taskId) => {
	const path = "/task/submit/" + taskId;
	const res = await ApiService.get(path);
	return res["data"];
};

export const getTasksCreated = async (page) => {
	const path = "/user/tasks/created";
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

export const getTasksSubmitted = async (page) => {
	const path = "/user/tasks/submitted";
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

export const payTask = async (data) => {
	const path = "/task/pay";
	return ApiService.post(path, data);
};

//////////////////
//// Programs ////
//////////////////

export const getPrograms = async (page) => {
	const path = "/program";
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

export const newProgram = async (program) => {
	const path = "/program";
	const res = await ApiService.post(path, program);
	return res["data"];
};

export const getProgramSubmissions = async (programId) => {
	const path = "/program/submit/" + programId;
	const res = await ApiService.get(path);
	return res["data"];
};

export const editProgram = async (data) => {
	const path = "/program/edit";
	return ApiService.post(path, data);
};

export const deletedProgram = async (data) => {
	const path = "/program/deleted";
	return ApiService.post(path, data);
}

export const submitProgram = async (data) => {
	const path = `/program/${data.program_type}/submit`;
	return ApiService.post(path, data);
};

export const assignCustomProgramWinners = async (data) => {
	const path = "/program/custom/assign";
	return ApiService.post(path, data);
};

export const assignCourseProgramWinners = async (data) => {
	const path = "/program/course/assign";
	return ApiService.post(path, data);
};

export const assignGenericProgramWinners = async (data) => {
	const path = "/program/generic/assign";
	return ApiService.post(path, data);
};

////////////////////////
//// New Task Modal ////
////////////////////////

export const newTask = async (task) => {
	const path = "/task";
	return ApiService.post(path, task);
};

///////////////////////
//// Notifications ////
///////////////////////

export const getNotificationsCount = async () => {
	const path = "/user/notification_count";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getNotifications = async (page) => {
	const path = "/user/notification";
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

//////////////////////
//// User Profile ////
//////////////////////

export const getUser = async () => {
	const path = "/user";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getUsersBirth = async () => {
	const path = "/users/birth";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getUsersAdmission = async () => {
	const path = "/users/admission";
	const res = await ApiService.get(path);
	return res["data"];
};

export const setNotificationSettings = async (settings) => {
	const path = "/user/notification_settings";
	const res = await ApiService.post(path, settings);
	return res["data"];
};

export const setNotificationSettingsWhatsapp = async (settings) => {
	const path = "/user/notification_settings_wpp";
	const res = await ApiService.post(path, settings);
	return res["data"];
};
export const getUserStats = async (id) => {
	const path = "/user/stats/" + id;
	const res = await ApiService.get(path);
	return res["data"];
};

/////////////////////////////
//// User Public Profile ////
/////////////////////////////

export const getPublicProfile = async (id) => {
	const path = `/user/${id}/public`;
	const res = await ApiService.get(path);
	return res["data"];
};

export const getUserSentFeedback = async (page, id) => {
	const path = "/user/posts/sent";
	const data = { user_id: id };
	const res = await ApiService.get(path, page, data);
	return { data: res["data"], next: res["next"] };
};

export const getUserReceivedFeedback = async (page, id) => {
	const path = "/user/posts/received";
	const data = { user_id: id };
	const res = await ApiService.get(path, page, data);
	return { data: res["data"], next: res["next"] };
};

//////////////
//// Cart ////
//////////////

export const getCart = async () => {
	const path = "/cart";
	const res = await ApiService.get(path);
	return res["data"];
};

/*
items = [
    {
        "amount": int,
        "productId": int,
    }
]
*/
export const addToCart = async (items) => {
	const path = "/cart";
	const data = { items };
	const res = await ApiService.post(path, data);
	return res["data"];
};
export const addToCartExternal = async (items) => {
	const path = "/cart/external";
	const data = { items };
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const updateCart = async (data) => {
	const path = "/update_cart";
	const res = await ApiService.post(path, data);
	return res["data"];
};
/*
items = [
    {
        "amount": int,
        "productId": int,
    }
]
*/
export const deleteFromCart = async (items) => {
	const path = "/cart";
	const data = { items };
	const res = await ApiService.delete(path, data);
	return res["data"];
};

export const buyProducts = async (data) => {
	const path = "/buy_products";
	const res = await ApiService.post(path, data);
	return res["data"];
};

///////////////////////
//// Communication ////
///////////////////////

export const sendCommAll = async (data) => {
	const path = "/communication/all";
	return ApiService.post(path, data);
};

export const sendCommGroup = async (data) => {
	const path = "/communication/group/" + data.id;
	return ApiService.post(path, data);
};

export const sendCommDepartment = async (data) => {
	const path = "/communication/department";
	return ApiService.post(path, data);
};

export const saveComm = async (data) => {
	const path = "/communication/draft";
	return ApiService.post(path, data);
};

export const getComms = async (page) => {
	const path = "/communications";
	return ApiService.get(path, page);
};

export const getAdminComms = async (page) => {
	const path = "/admin/communications";
	return ApiService.get(path, page);
};

export const getUserSurveys = async () => {
	const path = "/surveys";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getUserEvaluations = async () => {
	const path = "/evaluations";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getUserEvaluationsCompleted = async () => {
	const path = "/evaluations/completed";
	const res = await ApiService.get(path);
	return res["data"];
};


export const getGroupComms = async (group, page) => {
	const path = "/communications/group/" + group;
	return ApiService.get(path, page);
};

export const getDepartmentComms = async (department, page) => {
	const path = "/communications/department/" + department;
	return ApiService.get(path, page);
};

export const getCommGroup = async (id) => {
	const path = "/communication/group/" + id;
	const res = await ApiService.get(path);
	return res["data"];
};

export const getComunicationLogs = async (idPost) => {
	const path = `/communication/${idPost}/logs`
	const res = await ApiService.get(path)
	return res["data"]
}

export const searchNonCommUsers = async (id, keywords) => {
	const path = `/communication/${id}/user/search`;
	const res = await ApiService.post(path, { keywords });
	return res["data"];
};

export const createCommGroup = async ({name, users}) => {
	const path = "/communication/group";
	return ApiService.post(path, { name, users });
};

export const editCommGroup = async ({id, data}) => {
	const path = "/communication/group/" + id;
	return ApiService.patch(path, data);
};

export const deleteCommGroup = async (id) => {
	const path = "/communication/group/" + id;
	return ApiService.delete(path);
};

export const pinComm = async (id) => {
	const path = `/communication/${id}/pin`;
	return ApiService.post(path);
};

export const getHistory = async () => {
	const path = "/account/history";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getLatestComm = async () => {
	const path = "/communication/pinned";
	try {
		const res = await ApiService.get(path);
		return res["data"];
	} catch (_e) {
		return null;
	}
};

export const expireComm = async (data) => {
	const path = "/communication/expire";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const getCommGroups = async () => {
	const path = "/communication/groups";
	const res = await ApiService.get(path);
	return res["data"];
};

/////////////////
//// Catalog ////
/////////////////
export const getProductCategories = async () => {
	const path = "/product_category";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getOrgCatalogs = async () => {
	const path = "/catalogs";
	const res = await ApiService.get(path);
	return res["data"];
};

// Post

export const getPost = async (postId) => {
	const path = `/post/${postId}`;
	try {
		const res = await ApiService.get(path);
		return res["data"];
	} catch (_e) {
		return null;
	}
};

export const likePost = async (postId, userId) => {
	const path = "/like";
	const data = { postId, userId };
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const boostPost = async (data) => {
	const path = "/boost";
	return ApiService.post(path, data);
};

export const commentPost = async (postId, userId, msg) => {
	const path = "/comment";
	const data = { postId, userId, msg };
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const deleteComment = async (id) => {
	const path = "/comment/" + id;
	const res = await ApiService.delete(path);
	return res["data"];
};

export const updateComment = async (data) => {
	const path = "/comment/edit";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const getUserOrders = async (page) => {
	const path = "/orders";
	return ApiService.get(path, page);
};

export const updateRecognitionPostText = async (postId, data) => {
	const path = "/post/message/" + postId;
	const response = await ApiService.patch(path, data);
	return response["data"];
};

/////////////
//// Org ////
/////////////

export const getOrg = async (id) => {
	const path = "/org";
	const data = { org_id: id };
	const res = await ApiService.get(path, 1, data);
	return res["data"];
};

export const getOrgSettings = async () => {
	const path = "/org/settings";
	const res = await ApiService.get(path);
	return res["data"] || {};
};

export const editOrg = async (data) => {
	const path = "/org/edit";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const getOrgUsers = async (type, page, keywords, canSearchByEmail) => {
	const path = "/org/users";
	store.dispatch(clearOrgUsersAction());
	return ApiService.get(path, page, { type, keywords, search_by_email: canSearchByEmail });
};

export const getOrgOrders = async () => {
	const path = "/org/orders";
	const res = await ApiService.get(path);
	return res["data"];
};

export const searchOrgUser = async (keywords) => {
	const path = "/org/user/search";
	const res = await ApiService.post(path, { keywords });
	return res["data"];
};

export const importUsersFileCSV = async (data) => {
	const path = "/org/import/users";
	return ApiService.file(path, data);
};

export const unsubscribeUsersFileCSV = async (data) => {
	const path = "/org/unsubscribe/users";
	const res = await ApiService.file(path, data);
	return res["success"];
};

export const changeUserType = async (data) => {
	const path = "/user/type";
	return ApiService.post(path, data);
};

export const calculatePointsToDistribute = async (data) => {
	const path = "/org/points_to_distribute";
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const changeUserExtraPoints = async (data) => {
	const path = "/user/extra_points";
	return ApiService.post(path, data);
};

export const changeBasePoints = async (data) => {
	const path = "/org/base_points";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const editUserAsAdmin = async (data) => {
	const path = "/user/update";
	return ApiService.post(path, data);
};

export const approveRquest = async (data) => {
	const path = "/point_request/approve";
	return ApiService.post(path, data);
};

export const rejectRequest = async (data) => {
	const path = "/point_request/reject";
	return ApiService.post(path, data);
};

export const transferAdminPoints = async (data) => {
	const path = "/transfer/admin/points";
	return ApiService.post(path, data);
};

export const createNewUser = async (data) => {
	const path = "/user/create";
	return ApiService.post(path, data);
};

export const getAdminPrograms = async (page) => {
	const path = "/admin/program";
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

export const changeUserActiveState = async (data) => {
	const path = "/user/admin/active";
	return ApiService.post(path, data);
};

export const getPeopleSummary = async () => {
	const path = "/people/summary";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getComplaints = async () => {
	const path = "/org/complaint";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getSuggestions = async () => {
	const path = "/org/suggestion";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addComplaint = async (data) => {
	const path = "/complaint";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const archiveComplaint = async (id) => {
	const path = "/org/complaint/" + id + "/archive";
	const res = await ApiService.post(path);
	return res["success"];
};

export const archiveSuggestion = async (id) => {
	const path = "/org/suggestion/" + id + "/archive";
	const res = await ApiService.post(path);
	return res["success"];
};

export const createGoalChecklistItem = async (id, data) => {
	const path = `/goal/${id}/checklist`;
	const res = await ApiService.post(path, { text: data });
	return res["data"];
};

export const patchDepartment = async ({id, data}) => {
	const path = `/org/department/${id}`;
	const res = await ApiService.patch(path, data);
	return res["data"];
};

export const patchPostType = async ({id, data}) => {
	const path = `/org/recognition_type/${id}`;
	const res = await ApiService.patch(path, data);
	return res["data"];
};

export const deletePostType = async (id) => {
	const path = `/org/recognition_type/${id}`;
	return ApiService.delete(path);
};

export const updateGoalChecklist = async (goalid, data) => {
	const path = `/goal/${goalid}/checklist/${data.id}`;
	const res = await ApiService.patch(path, data);
	return res["data"];
};

export const deleteChecklist = async (goalid, id) => {
	const path = `/goal/${goalid}/checklist/${id}`;
	const res = await ApiService.delete(path);
	return res["data"];
};

export const addSuggestion = async (data) => {
	const path = "/suggestion";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const getAllDepartments = async () => {
	const path = "/org/departments";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getDepartments = async () => {
	const path = "/departments";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addNewDepartment = async (data) => {
	const path = "/org/department";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const addNewGoalCategory = async (data) => {
	const path = "/org/goal_category";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const deleteGoalCategory = async (id) => {
	const path = `/org/goal_category/${id}`;
	const res = await ApiService.delete(path);
	return res["success"];
};

export const editGoalCategory = async (id, data) => {
	const path = "/org/goal_category/" + id;
	const res = await ApiService.patch(path, data);
	return res["data"];
};

export const addNewProgramCategory = async (data) => {
	const path = "/org/program_category";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const deleteProgramCategory = async (id) => {
	const path = `/org/program_category/${id}`;
	const res = await ApiService.delete(path);
	return res["success"];
};

export const editProgramCategory = async (id, data) => {
	const path = "/org/program_category/" + id;
	const res = await ApiService.patch(path, data);
	return res["data"];
};

export const getProgramCategories = async () => {
	const path = "/program_category";
	const res = await ApiService.get(path);
	return res["data"];
};

export const activateDepartment = async (data) => {
	const path = "/org/department/activate";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const getAllValues = async () => {
	const path = "/org/values";
	const res = await ApiService.get(path);
	return res["data"];
};

export const addNewValue = async ({data}) => {
	const path = "/org/value";
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const editValue = async ({id, data}) => {
	const path = "/org/value/" + id;
	const res = await ApiService.patch(path, data);
	return res["success"];
};

export const deleteValue = async ({id}) => {
	const path = "/org/value/" + id;
	const res = await ApiService.delete(path);
	return res["success"];
};

///////////////////
//// Analytics ////
///////////////////

export const getSocialPointsStats = async () => {
	const path = "/analytics/social_points_recognitions";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getTotalPoints = async () => {
	const path = "/analytics/total_points";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getAnalyticsOverview = async () => {
	const path = "/analytics/overview";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getPointsByChannel = async () => {
	const path = "/analytics/points_by_channel";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getAllPointsMonthly = async () => {
	const path = "/analytics/points_by_channel_by_month";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getPostsBytype = async () => {
	const path = "/analytics/recognition_types";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getPostsBytypePublic = async (user_id) => {
	const path = `/analytics/${user_id}/recognition_types`;
	const res = await ApiService.get(path);
	return res["data"];
};

//////////////////
//// Payments ////
//////////////////

export const getPayments = async () => {
	const path = "/payments";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getInvoices = async () => {
	const path = "/invoices";
	const res = await ApiService.get(path);
	return res["data"];
};

///////////////////
//// Analytics ////
///////////////////

export const getPaymentSecret = async (data) => {
	const path = "/create-points-intent";
	return ApiService.post(path, data);
};

export const submitSurvey = async (id, data) => {
	const path = `/survey/${id}/submit`;
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const submitEvaluation = async (id, data) => {
	const path = `/evaluation/${id}/submit`;
	const res = await ApiService.post(path, data);
	return res["data"];
};

export const activateSurvey = async (id) => {
	const path = `/org/survey/${id}/activate`;
	const res = await ApiService.patch(path);
	return res["success"];
};

export const activateProgram = async (id) => {
	const path = `/org/program/${id}/activate`;
	const res = await ApiService.patch(path);
	return res["success"];
};

export const multiResponseActivateSurvey = async (id) => {
	const path = `/org/survey/${id}/multi_response`;
	const res = await ApiService.patch(path);
	return res["success"];
};

export const activateDateSurvey = async (id, data) => {
	const path = `/org/survey/schedule/` + id;
	const res = await ApiService.post(path, data);
	return res["success"];
};

export const setSurveyPoints = async (id, points) => {
	const path = `/org/survey/${id}/amount`;
	return ApiService.patch(path, { amount: points });
};

export const notifySurvey = async ({id, data}) => {
	const path = `/org/survey/${id}/notify`;
	return ApiService.post(path, data);
};

export const getCustomAnalytics = async () => {
	const path = "/custom_analytics";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getPublicCustomAnalytics = async () => {
	const path = "/custom_analytics/public";
	const res = await ApiService.get(path);
	return res["data"];
};

export const getActivevsInactive = async () => {
	const path = "/active_vs_inactive";
	const res = await ApiService.get(path);
	return res["data"];
};

export const setTeamsSession = async (token) => {
	const path = `/oauth2/teams/token`;
	const res = await ApiService.post(path, { token });
	return res["data"];
};
export const getPointsByMonth = async (year, month)=>{
	const path = `/analytics/monthly_points_summary`
	const data = { year: year, month: month }
	const res = await ApiService.get(path, 1, data);
	return { data: res["data"]}

}
// Special Recognitions
export const getUserRecognitions = async (page, id) => {
	const path = `/post/special/recognitions`;
	const data = { user_id: id };
	const res = await ApiService.get(path, page, data);
	return { data: res["data"], next: res["next"] };
};
export const getOrgRecognitions = async (page) => {
	const path = `/org/recognition`;
	const res = await ApiService.get(path, page);
	return { data: res["data"], next: res["next"] };
};

export const editOrgRecognition = async (id, data) => {
  const path = `/org/recognition/${id}`
  return ApiService.patch(path, data)
}

export const createOrgRecognition = async (data) => {
  const path= '/org/recognition'
  return ApiService.post(path, data)
}

export const sendOrgRecognition = async (id, data) => {
  const path = `/org/send/recognition/${id}`
  const res = await ApiService.post(path, data)
  return res["success"]
}
export const getSpecialRecognitionsHistory = async (page,id) => {
	const path = `/org/get_recognition_by_id/`
	const data = { id_recognition: id };
	const res = await ApiService.get(path, page, data);
	return { data: res['data'], next: res['next']}

}
//////////////////
//// Countries ///
//////////////////

export const getCountries= async () => {
	const path = '/countries'
	const res = await ApiService.get(path)
	return res["data"]
}

//////////////////
//// Typeform- ///
//////////////////
// Forms management
export const createNewTypeform = async (formData, headers) => {
	const path = '/typeform/create'
	const res = await ApiService.post(path, formData, headers)
	return res["data"]
}

export const deteleteTypeform = async (formId) => {
	const path = `/typeform/${formId}/delete`
	const res = await ApiService.delete(path)
	return res["data"]
}

export const getTypeform = async (formId) => {
	const path = `/typeform/${formId}/get`
	const res = await ApiService.get(path)
	return res["data"]
}

export const getAllTypeforms = async (page) => {
	const path = `/typeform/list`
	const res = await ApiService.get(path, page)
	return res["data"]
}

export const updateTypeform = async (formId, dataToUpdate) => {
	const path = `/typeform/${formId}/update/`
	const res = await ApiService.patch(path, dataToUpdate)
	return res["data"]
}

// Responses management
export const getTypeformResponse = async (formId, responseId ) => {
	const path = `/typeform/${formId},${responseId}/response`
	const res = await ApiService.get(path)
	return res["data"]
}

export const getAllTypeformsResponses = async () => {
	const path = `/typeform/responses`
	const res = await ApiService.get(path)
	return res["data"]
}
//////////////////
//// Typeform- ///
//////////////////
export const getAllTyC = async () => {
	const path = '/staff/tyc';
	const res = await ApiService.get(path)
	return res['data']
}
