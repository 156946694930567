import { SET_DEVICE } from "./actions";

const INITIAL_STATE = {
  isDesktop: true,
};

export default function chartsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_DEVICE:
      return { ...state, isDesktop: action.isDesktop };
    default:
      return state;
  }
}
