import axios from "axios";
import { getToken } from "@/helpers/storage";
import { history } from "@components/Navigation";
import * as Sentry from "@sentry/react";
import store from "@/redux/store"
import {addBannerMessage} from "@/redux/actions";
const isPdb = /.*pdb\.motivy.*/.test(window.origin)

const errors = [
	"user-not-enough-points",
	"failed-login",
	"invalid-reset-token",
	"invalid-old-password",
	"duplicated-email",
	"duplicated-phone",
	"self-activation",
	"duplicated-generic-submission",
	"duplicated-user-selected-generic-submission",
	"duplicated-user-custom-submission",
	"duplicated-user-course-submission",
	"self-recognition",
	"duplicated-boost",
	"self-task-submission",
	"org-user-not-active",
	"duplicate-phone",
	"no-phone",
	"twilio-phone-error",
	"something-depends-on-this",
];

const injectTokenHeader = (headers) => {
	// check if token is not expired, if expired remove from local storage, remove authenticated flag
	const token = getToken();
	return {
		...headers,
		Authorization: `Bearer ${token}`,
		Accept: "application/json",
	};
};

const nonHandledRoutes = ["login", "logout", "error", "reset_password", "recover"];

const getQuery = () => {
	let search = window.location.search;
	let params = new URLSearchParams(search);
	let staff_login = params.get("staff_login");
	let user = params.get("user");
	let org = params.get("org");
	if (staff_login && user && org) {
		return `staff_login=${staff_login}&user=${user}&org=${org}`;
	} else return false;
};

const logParams = {};
logParams.requestContext = {};
logParams.requestContext.http = {};
logParams.body = {};

const getDeviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return "Tablet";
  }
  if (
    /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return "Mobile";
  }
  return "Desktop";
};

function  handleError(){
	store.dispatch(addBannerMessage({ errorMessage: "Upps. Algo salio mal" }))
	window.history.go(1);
}

function handleExcept (err, auth_path='/logout') {
	const location = history.location.pathname;
	if (err.response) {
		const {data, status} = err.response
		if (status === 401){
			if (nonHandledRoutes.find((route) => route.split("/")[0] === location))
				return err;
			if(!location.includes('login')) history.push(auth_path);
		} else if (data && data["success"] === false){
			if (errors.find((e) => e === data["error"]) === undefined) {
				handleError()
				Sentry.captureException(new Error(data["error"]));
			}
			return data
		}
	} else {
		if ("/maintenace" === location) return err;
		handleError()
		Sentry.captureException(err);
	}
}

export const ApiService = {
	baseUrl: process.env.REACT_APP_API_ENDPOINT,

	get: async function (path, page, payload, headers) {
		const fullHeaders = injectTokenHeader({
			...headers,
			"Content-Type": "application/json",
		});
		if (page) {
			path += "?page=" + page;
		}

		const query = getQuery();

		let url = this.baseUrl + path;

		if (query) {
			url = page ? url + "&" + query : url + "?" + query;
		}

		try {
			const { data } = await axios.get(url, {
				headers: fullHeaders,
				params: payload,
			});
			if (data["success"] === false) {
				if (errors.find((e) => e === data["error"]) === undefined) {
					handleError()
					Sentry.captureException(new Error(data["error"]));
				}
			}
			if (data["error"] === "org-user-not-active") {
				return {
					data: {
						error: {
							not_active: true,
						},
						org: {
							language: "spanish",
							settings: {},
						},
						name: "F",
						lastname: "V",
					},
				};
			}
			return data;
		} catch (err) {
			if (isPdb) {
				fetch(`${process.env.REACT_APP_API_ENDPOINT}/telegram/report`, {
					method: "POST",
					body: JSON.stringify({ message: `${err}`})
				})
			}
			const location = history.location.pathname;
			if (err.response) {
				const {data, status} = err.response
				if (status === 401){
					if (nonHandledRoutes.find((route) => route === location.split("/")[1]))
						return err;
					history.push("/login");
				} else if (data && data["success"] === false){
					if (errors.find((e) => e === data["error"]) === undefined) {
						handleError()
						Sentry.captureException(new Error(data["error"]));
					}
					if (data["error"] === "org-user-not-active") {
						return {
							data: {
								error: {
									not_active: true,
								},
								org: {
									language: "spanish",
									settings: {},
								},
								name: "F",
								lastname: "V",
							},
						};
					}
					return data
				}
			} else {
				if ("/maintenance" === location) return err;
				history.push("/maintenance");
				Sentry.captureException(err);
			}
		}
	},

	post: async function (path, payload, headers) {
		const fullHeaders = injectTokenHeader({
			...headers,
			"Content-Type": "application/json",
		});

		let url = this.baseUrl + path;
		let logUrl = this.baseUrl + "/log";
		let valid = false;
		if(path === "/login" || path === "/otp/login" || path === "/otp/verify_code"){
			valid = true;
		}
		const query = getQuery();

		if (query) {
			return handleError()
		}

		try {
			logParams.body.request_timestamp = new Date();
			const { data } = await axios.post(url, payload, { headers: fullHeaders });
			logParams.body.response_timestamp = new Date();
			logParams.body.duration = (logParams.body.response_timestamp.getTime() - logParams.body.request_timestamp.getTime());
			logParams.body.request_type = "POST";
			logParams.body.request_path = path;
			logParams.body.device_type = getDeviceType();
			logParams.requestContext.http.method = "POST";
			
			if (valid && data["success"] === true) {
				logParams.body.response_status = data.success;
				logParams.body.error = 'Exited with status code 200';
				logParams.body.user_id = data.data.id
			}
			
			if (data["success"] === false) {
				logParams.body.response_status = data.success;
				logParams.body.error = data["error"];
				if (errors.find((e) => e === data["error"]) === undefined) {
					handleError()
					Sentry.captureException(new Error(data["error"]));
				}
				// How to handle failed api calls?
				// check error status code? see if its unauthorized, bad, request, etc
			}
			return data;
		} catch (error) {
			if (isPdb) {
				fetch(`${process.env.REACT_APP_API_ENDPOINT}/telegram/report`, {
					method: "POST",
					body: JSON.stringify({ message: `${error}`})
				})
			}
			return handleExcept(error)
		} finally {
			if(valid){
				const obj = JSON.stringify(logParams);
				axios.post(logUrl, obj, { headers: fullHeaders });
			}
		}
	},
	file: async function (path, payload, headers) {
		const fullHeaders = injectTokenHeader({
			...headers,
			"Content-Type": "multipart/form-data",
		});

		let url = this.baseUrl + path;

		const query = getQuery();

		if (query) {
			return handleError()
		}

		try {
			const { data } = await axios.post(url, payload, { headers: fullHeaders });
			if (data["success"] === false) {
				if (errors.find((e) => e === data["error"]) === undefined) {
					handleError()
					Sentry.captureException(new Error(data["error"]));
				}
				// How to handle failed api calls?
				// check error status code? see if its unauthorized, bad, request, etc
			}
			return data;
		} catch (error) {
			if (isPdb) {
				fetch(`${process.env.REACT_APP_API_ENDPOINT}/telegram/report`, {
					method: "POST",
					body: JSON.stringify({ message: `${error}`})
				})
			}
			return handleExcept(error)
		}
	},

	delete: async function (path, payload, headers) {
		const fullHeaders = injectTokenHeader({
			...headers,
			"Content-Type": "application/json",
		});

		const query = getQuery();

		if (query) {
			return handleError()
		}

		try {
			const { data } = await axios.delete(this.baseUrl + path, {
				headers: fullHeaders,
				data: payload,
			});

			if (data["success"] === false) {
				if (errors.find((e) => e === data["error"]) === undefined) {
					handleError()
					Sentry.captureException(new Error(data["error"]));
				}

				return data;
			}
			return data;
		} catch (error) {
			if (isPdb) {
				fetch(`${process.env.REACT_APP_API_ENDPOINT}/telegram/report`, {
					method: "POST",
					body: JSON.stringify({ message: `${error}`})
				})
			}
			return handleExcept(error)
		}
	},

	patch: async function (path, payload, headers) {
		const query = getQuery();

		if (query) {
			return handleError()
		}

		const fullHeaders = injectTokenHeader({
			...headers,
			"Content-Type": "application/json",
		});
		
		try {
			const { data } = await axios.patch(this.baseUrl + path, payload, {
				headers: fullHeaders,
			});
			if (data["success"] === false) {
				if (errors.find((e) => e === data["error"]) === undefined) {
					handleError()
					Sentry.captureException(new Error(data["error"]));
				}
			}
			return data;
		} catch (error) {
			if (isPdb) {
				fetch(`${process.env.REACT_APP_API_ENDPOINT}/telegram/report`, {
					method: "POST",
					body: JSON.stringify({ message: `${error}`})
				})
			}
			return handleExcept(error)
		}
	},
};