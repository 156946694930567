import { UPDATE_EVENT_LIST } from "./actions";

const initialState = {
    events: [],
  };
  
  const eventReducer = (state = initialState, action) => {
    switch (action.type) {
      case UPDATE_EVENT_LIST:
        return {
          ...state,
          events: action.payload,
        };
      case 'UPDATE_EVENT_AFTER_EDIT':
        const updatedEvent = action.payload;
        const updatedEvents = state.events.map((event) => {
          if (event.id === updatedEvent.id) {
            return updatedEvent;
          }
          return event;
        });
        return {
          ...state,
          events: updatedEvents,
        };
      case 'UPDATE_EVENT_AFTER_DELETE':
        const deletedEventId = action.payload;
        const filteredEvents = state.events.filter(
          (event) => event.id !== deletedEventId
        );
        return {
          ...state,
          events: filteredEvents,
        };
      default:
        return state;
    }
  };  
  
export default eventReducer;
  