import { SET_ORG, GET_ORG_SETTINGS, SET_ORG_USERS, CLEAR_ORG } from "./actions";

const INITIAL_STATE = {
  org: null,
  users: [],
  orgSettings: null,
};

export default function orgReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ORG:
      return { ...state, org: action.org };
    case CLEAR_ORG:
      return { ...state, users: [] };
    case SET_ORG_USERS:
      return { ...state, users: [...state.users, ...action.users] };
    case GET_ORG_SETTINGS:
      return { ...state, orgSettings: action.orgSettings}
    default:
      return state;
  }
}
