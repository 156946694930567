import { ADD_BANNER_MESSAGE, REMOVE_BANNER_MESSAGE } from "./actions";

const INITIAL_STATE = {
  bannerMessages: [],
};

export default function bannerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_BANNER_MESSAGE: {
      const { bannerMessages } = state;
      const _bannerMessages = bannerMessages.slice().concat(action.message);
      return { ...state, bannerMessages: _bannerMessages };
    }
    case REMOVE_BANNER_MESSAGE: {
      const { bannerMessages } = state;
      const _bannerMessages = bannerMessages.slice(1);
      return { ...state, bannerMessages: _bannerMessages };
    }
    default:
      return state;
  }
}
