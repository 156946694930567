import { SET_COUNTRIES } from "./actions";

const INITIAL_STATE = {
    countries: [],
};

export default function countriesReducer(state = INITIAL_STATE, action) {
    if ( action.type === SET_COUNTRIES ) return { ...state, countries: action.countries }
    return state
}
