import { lazy, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import store, { RootState } from '@/redux/store';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApiContext } from '@/context/ApiContext';

// TODO: remove this styles once migration to MUI its done
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'semantic-ui-css/semantic.min.css';
import './components/styles/App.sass';

/** MUI Dependencies */
import { ThemeProvider } from '@mui/material/styles';
import { getTheme } from './MUITheme';

/** Google Analytics */
import ReactGa from 'react-ga4';

/** Services */
import { getAvailableModules, getNotificationsCount, getOrgSettings, getUser } from '@/service/api';
import {
	setLanguagesAction,
	setMeAction,
	setNotificationsCountAction,
	setServices,
	setSurveyCount,
} from '@/redux/actions';

/** Utils */
import { objectEmpty } from '@/utils';
import { Settings } from '@/utils/luxon';

/** Components */
import LoaderPage from '@/pages/Loader/LoaderPage';
import { AlertContext } from '@/context/AlertContext';
import StreamedMessages from '@/ui2.0/Alerts/StreamedMessages';

import {TBranding} from "@/types";

const Messages = lazy(() => import('@/ui/MessageContainer/MessagesContainer'));
const Layout = lazy(() => import('@/layouts'));
const FullStory = lazy(() => import('react-fullstory'));

//Google Analytics 4
let ga_measure_id;
if (/.*dev\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-7J9H5D94W8';
} else if (/.*pdb\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-0795SHCM2E';
} else if (/.*coderland\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-TZWKNZV8HD';
} else if (/.*olx\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-7LED68S89H';
} else if (/.*3acomposites\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-WD8CP2E4MN';
} else if (/.*demo\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-57745B3YW6';
} else if (/.*fiducia\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-SXZTWGDYK3';
} else if (/.*colourrepublic\.motivy.*/.test(window.origin)) {
	ga_measure_id = 'G-3X5ECP49DD';
} else {
	ga_measure_id = 'G-7J9H5D94W8';
}

ReactGa.initialize(ga_measure_id);
ReactGa.pageview('/');

const languages: Record<string, string> = {
	english: 'en',
	spanish: 'es',
};

/** Main component */
const App = (): ReactElement => {
	/** Hooks */
	const { i18n } = useTranslation();
	const [isLoading, setIsLoading] = useState(true);
	const [branding, setBranding] = useState<TBranding>(null);
	const { fullstory } = useSelector((state: RootState) => state.services);

	/** Configs for login in with Teams */
	const [apiEndpoint] = useState(process.env.REACT_APP_API_ENDPOINT || null);
	const [apiResponses, setApiResponses] = useState({
		token: localStorage.getItem('auth_token') || null,
		createdCommunicationGroup: null,
	});

	const setTeamsSession = useCallback(
		async (microsoftToken: string) => {
			let response = null;
			try {
				const r = await axios(`${apiEndpoint}/oauth2/teams/token`, {
					method: 'POST',
					data: { token: microsoftToken },
				});
				if (r && r.status === 200) {
					const t = r.data?.data?.auth_token;
					setApiResponses((c) => ({
						...c,
						token: t,
					}));
					response = t;
					localStorage.setItem('auth_token', t);
				}
			} catch (error) {
				console.log(error);
				response = null;
				// TODO Invoke sentry
			}
			return response;
		},
		[apiEndpoint],
	);

	useEffect(() => {
		/** Modules available */
		async function getModules() {
			return await getAvailableModules();
		}

		getModules().then((values) => store.dispatch(setServices(values || {})));

		/** Notifications */
		const notifications = async () => {
			const { notification_count, pending_surveys } = await getNotificationsCount();
			store.dispatch(setNotificationsCountAction(notification_count || 0));
			store.dispatch(setSurveyCount(pending_surveys || 0));
		};

		/** Org settings */
		const orgSettings = async () => {
			const { branding: orgBranding } = await getOrgSettings();
			if (orgBranding && !objectEmpty(orgBranding)) {
				setBranding(orgBranding);
			}
		};

		/** Load user logged in */
		const loadUser = async () => {
			const user = await getUser();
			if (user) {
				const userLang = languages[user.language] || 'es';
				if (userLang !== i18n.language) {
					Settings.defaultLocale = userLang;
					await i18n.changeLanguage(userLang);
				}
				await notifications();
			}
			await orgSettings();
			store.dispatch(setLanguagesAction(Object.keys(languages)));
			store.dispatch(setMeAction(user ?? null, true));
		};
		loadUser().then(() => setTimeout(() => setIsLoading(false), 3000));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const mainColor = branding?.banner?.main || undefined;
	const secondaryColor = branding?.banner?.secondary || undefined;

	return (
		<>
			<ThemeProvider theme={getTheme(mainColor, secondaryColor)}>
				<AlertContext.Provider value={useMemo(() => ({ caller: (f: Function) => f() }), [])}>
					<ApiContext.Provider
						value={useMemo(
							() => ({
								apiResponses,
								post: { setTeamsSession },
							}),
							[apiResponses, setTeamsSession],
						)}
					>
						<QueryClientProvider client={new QueryClient()}>
							<BrowserRouter>
								<>
									{isLoading && <LoaderPage />}
									{fullstory?.active && <FullStory org={'TK1E0'} />}
									<Layout show={!isLoading}
											logo={branding?.banner?.logo || undefined}
											navBarColor={branding?.headerBarColor} />
									<Messages />
									<StreamedMessages />
								</>
							</BrowserRouter>
						</QueryClientProvider>
					</ApiContext.Provider>
				</AlertContext.Provider>
			</ThemeProvider>
		</>
	);
};

export default App;
