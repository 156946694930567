import {ADD_STREAMED_MESSAGE, DELETE_STREAMED_MESSAGE} from "@/redux/actions";

const INITIAL_STATE = [
    // {
    //     id: '123',
    //     severity: 'success',
    //     data: 'done',
    //     message: 'This is a test message',
    //     success: true
    // },
    // {
    //     id: '456',
    //     severity: 'warning',
    //     data: 'done',
    //     message: 'This is a test message 32',
    //     success: true
    // }
]

export default function streamedMessagesReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case ADD_STREAMED_MESSAGE:
            return [
                ...state,
                action.payload
            ]
        case DELETE_STREAMED_MESSAGE:
            console.log('Trying to delete message ', action.payload)
            console.log(state)
            return state.filter(m => m.id !== action.payload)
        default:
            return state
    }
}