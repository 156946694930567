import { SET_POSTS } from "./actions";

const INITIAL_STATE = {
  posts: [],
};

export default function postsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_POSTS:
      return { ...state, posts: action.posts };
    default:
      return state;
  }
}
