import { SET_POST_TYPES } from "./actions";
import { UPDATE_POST_TYPE, DELETE_POST_TYPE } from "./actions";

const INITIAL_STATE = {
	post_types: [],
};

export default function postTypesReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case SET_POST_TYPES:
			return { ...state, post_types: action.post_types };
		case UPDATE_POST_TYPE:
			return {
				...state,
				post_types: [
					...state.post_types.filter((t) => t.id !== action.post_type.id),
					action.post_type,
				],
			};
		case DELETE_POST_TYPE:
			return {
				...state,
				post_types: state.post_types.filter(
					(t) => t.id !== action.post_type.id,
				),
			};
		default:
			return state;
	}
}
