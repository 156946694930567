import axios from 'axios';

export const SET_ME = 'SET_ME';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_CART = 'SET_CART';
export const SET_POSTS = 'SET_POSTS';
export const SET_CHARTS = 'SET_CHARTS';
export const SET_NOTIFICATIONS_COUNT = 'SET_NOTIFICATIONS_COUNT';
export const SET_TASKS = 'SET_TASKS';
export const UPDATE_TASKS = 'UPDATE_TASKS';
export const SET_PROGRAMS = 'SET_PROGRAMS';
export const UPDATE_PROGRAMS = 'UPDATE_PROGRAMS';
export const SET_ORG = 'SET_ORG';
export const CLEAR_ORG = 'CLEAR_ORG';
export const SET_ORG_USERS = 'SET_ORG_USERS';
export const SET_FEATURED_ITEMS = 'SET_FEATURED_ITEMS';
export const TRIGGER_SETTINGS = 'TRIGGER_SETTINGS';
export const TRIGGER_WHATSAPP = 'TRIGGER_WHATSAPP';
export const ADD_BANNER_MESSAGE = 'ADD_BANNER_MESSAGE';
export const REMOVE_BANNER_MESSAGE = 'REMOVE_BANNER_MESSAGE';
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS';
export const SET_POST_TYPES = 'SET_POST_TYPES';
export const SET_GOALS = 'SET_GOALS';
export const UPDATE_GOAL = 'UPDATE_GOAL';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';
export const UPDATE_POST_TYPE = 'UPDATE_POST_TYPE';
export const DELETE_POST_TYPE = 'DELETE_POST_TYPE';
export const SET_DEVICE = 'SET_DEVICE';
export const SET_SURVEY = 'SET_SURVEY';
export const SET_GOAL_CATEGORIES = 'SET_GOAL_CATEGORIES';
export const UPDATE_GOAL_CATEGORY = 'UPDATE_GOAL_CATEGORY';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_PERIODS = 'SET_PERIODS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const SET_CATALOGS = 'SET_CATALOGS';
export const SET_IS_LOGOUT = 'SET_IS_LOGOUT';
export const ADD_STREAMED_MESSAGE = 'ADD_STREAMED_MESSAGE';
export const DELETE_STREAMED_MESSAGE = 'DELETE_STREAMED_MESSAGE';

export const GET_ORG_SETTINGS = 'GET_ORG_SETTINGS';
export const UPDATE_EVENT_LIST = 'UPDATE_EVENT_LIST';


export const updateEventList = (events) => {
	return {
	  type: 'UPDATE_EVENT_LIST',
	  payload: events,
	};
  };
  
export const updateEventAfterEdit = (event) => {
	return {
		type: 'UPDATE_EVENT_AFTER_EDIT',
		payload: event,
	};
};

export const updateEventAfterDelete = (eventId) => {
	return {
		type: 'UPDATE_EVENT_AFTER_DELETE',
		payload: eventId,
	};
};
  

/** Async actions */
export const getOrgSettings = (auth_token) => {
	return async function (dispatch) {
		let orgSettings = null
		try {
			if (auth_token) {
				const { data } = await axios(`${process.env.REACT_APP_API_ENDPOINT}/org/settings`, {
					method: 'GET',
					headers: { Authorization: `Bearer ${auth_token}` },
				});
				orgSettings = data
			}
		} catch (err) {
			console.log(err);
		}

		return dispatch({
			type: GET_ORG_SETTINGS,
			orgSettings: orgSettings?.data || null,
		});
	};
};

/** Sync actions */
export const setSurveyCount = (surveyCount) => ({
	type: SET_SURVEY,
	surveyCount,
});

export const setDevice = (isDesktop) => ({
	type: SET_DEVICE,
	isDesktop,
});

export const updatePostType = (post_type) => ({
	type: UPDATE_POST_TYPE,
	post_type,
});

export const delPostType = (post_type) => ({
	type: DELETE_POST_TYPE,
	post_type,
});

export const updateDepartment = (department) => ({
	type: UPDATE_DEPARTMENT,
	department,
});

export const updateGoal = (goal) => ({
	type: UPDATE_GOAL,
	goal,
});

export const setGoals = (goals) => ({
	type: SET_GOALS,
	goals,
});

export const setPostTypes = (post_types) => ({
	type: SET_POST_TYPES,
	post_types,
});

export const setDepartment = (departments) => ({
	type: SET_DEPARTMENTS,
	departments,
});

export const addBannerMessage = (message) => ({
	type: ADD_BANNER_MESSAGE,
	message,
});

export const removeBannerMessage = (index) => ({
	type: REMOVE_BANNER_MESSAGE,
	index,
});

export const triggerSettings = (show) => ({
	type: TRIGGER_SETTINGS,
	show,
});

export const triggerWhatsapp = (show) => ({
	type: TRIGGER_WHATSAPP,
	show,
});

export const setFeaturedItems = (items) => ({
	type: SET_FEATURED_ITEMS,
	items,
});

export const setOrgUsers = (users) => ({
	type: SET_ORG_USERS,
	users,
});

export const setOrgAction = (org) => ({
	type: SET_ORG,
	org,
});

export const clearOrgUsersAction = () => ({
	type: CLEAR_ORG,
});

export const setMeAction = (user, initialized) => ({
	type: SET_ME,
	user,
	initialized,
});

export const setLanguagesAction = (languages) => ({
	type: SET_LANGUAGES,
	languages,
});

export const setNotificationsCountAction = (count) => ({
	type: SET_NOTIFICATIONS_COUNT,
	count,
});

export const setTasksAction = (tasks) => ({
	type: SET_TASKS,
	tasks,
});

export const updateTasksAction = (task) => ({
	type: UPDATE_TASKS,
	task,
});

export const setProgramsAction = (programs) => ({
	type: SET_PROGRAMS,
	programs,
});

export const updateProgramsAction = (program) => ({
	type: UPDATE_PROGRAMS,
	program,
});

export const setPostsAction = (posts) => ({
	type: SET_POSTS,
	posts,
});

export const setChartssAction = (charts) => ({
	type: SET_CHARTS,
	charts,
});

export const setCartAction = (cart, initialized) => {
	return {
		type: SET_CART,
		cart,
		initialized,
	};
};

export const setGoalCategory = (categories) => {
	return {
		type: SET_GOAL_CATEGORIES,
		categories,
	};
};

export const updateGoalCategory = (category) => {
	return {
		type: UPDATE_GOAL_CATEGORY,
		category,
	};
};

export const setServices = (services) => {
	if (services) {
		return {
			type: SET_SERVICES,
			...services,
		};
	}
};

export const setPeriods = (periods) => {
	if (periods) {
		return {
			type: SET_PERIODS,
			periods,
		};
	}
};

export const setCountries = (countries) => {
	return {
		type: SET_COUNTRIES,
		countries,
	};
};

export const setCatalogs = (catalogs) => {
	return {
		type: SET_CATALOGS,
		catalogs,
	};
};

export const setIsLogoutFlag = (value) => ({
	type: SET_IS_LOGOUT,
	value,
});

export const addStreamedMessage = (message) => ({
	type: ADD_STREAMED_MESSAGE,
	payload: message,
});

export const deleteStreamedMessage = (messageId) => ({
	type: DELETE_STREAMED_MESSAGE,
	payload: messageId,
});
