import { SET_CART } from "./actions";

const INITIAL_STATE = {
  cart: [],
  initialized: false,
};

export default function cartReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CART:
      return { ...state, cart: action.cart, initialized: action.initialized };
    default:
      return state;
  }
}
