import { SET_IS_LOGOUT } from "@/redux/actions";

const INITIAL_STATE = {
    value: false
}

export default function commonsReducer(state = INITIAL_STATE, {type, value}) {
    switch (type) {
        default:
            return state
        case SET_IS_LOGOUT:
            return {
                ...state,
                value
            }
    }
}